<template>
  <div class="registrations">
    <header class="w3-container w3-theme-l1">
      <h4>
        {{ $t('tournaments.registrations') }}
      </h4>
      <h4>
        <span v-if="loggedIn" @click="click" class="w3-button w3-round theme-button-express">
          <font-awesome-icon icon="plus"/>
        </span>
      </h4>
    </header>
    <div class="details w3-theme-l2">
      <registration-card v-for="registration in sortedRegistrations"
                         :key="registration.tournamentId + '#' + registration.personId"
                         :registration="registration"></registration-card>
    </div>
  </div>
</template>
<!--let path = 'tournaments/' + this.registration.tournamentId + '/registrations/' + this.registration.personId-->


<script>
import {RestAPI} from "@aws-amplify/api-rest";
import RegistrationCard from "@/components/tournaments/registrations/RegistrationCard";
import {user} from "@/components/users";

const apiName = 'api';
const myInit = { // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

export default {
  name: 'RegistrationsList',
  components: {RegistrationCard},
  props: {
    tournamentId: String,
  },
  data: function () {
    return {
      registrations: []
    }
  },
  computed: {

    loggedIn() {
      return user.loggedIn
    },
    sortedRegistrations() {
      return Array.from(this.registrations).sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    }
  },
  methods: {
    click() {
      this.$router.push({
        name: 'EditRegistration',
        params: {tournamentId: this.tournamentId, personId: "create"},
        query: {mode: 'create'}
      })
    },
  },
  beforeMount() {
    let path = '/tournaments/' + this.tournamentId + '/registrations';
    let that = this
    RestAPI
        .get(apiName, path, myInit)
        .then(response => {
          that.registrations = response
        })
        .catch(error => {
          console.log(error.response);
        });
  },
}
</script>

<style scoped>

a {
  text-decoration: none;
}

header {
  display: flex;
  justify-content: space-between;
}

h4 {
  flex: auto;
}

.container {
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
  }
}

</style>

