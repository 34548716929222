<template>
  <modal class="modalOverride" v-show="true">
    <template v-slot:body>
      <div class="confirm">
        <div class="w3-container w3-blue-gray"><h3>bevestig met code</h3></div>
        <p>U ontvangt een email waarin een code staat vermeld.
          Bevestig uw email adres door hieronder deze code in te vullen en te versturen.</p>
        <div class="input">
          <label class="w3-text-blue-gray"><b>code</b>
            <input v-model="codeInput" class="w3-input w3-border" type="text">
          </label>
        </div>
      </div>
      <section class="buttons">
        <button class="w3-btn w3-border w3-border-blue-gray w3-hover-blue-gray" @click="home">terug</button>
        <button class="w3-btn w3-blue-gray w3-right" @click="confirm">versturen</button>
      </section>
    </template>
  </modal>

</template>

<script>
import {Auth} from "@aws-amplify/auth";
import Modal from "@/components/Modal";

export default {
  name: 'Confirm',
  components: {Modal},
  data: function () {
    return {
      codeInput: "",
      errorMessage: "",
    }
  },
  methods: {
    confirm() {
      let that = this
      Auth.confirmSignUp(this.$route.params.username, this.codeInput)
          .then(function () {
            that.$router.push('/sign-in')
          })
          .catch(function (error) {
            console.log("ERROR = ", JSON.stringify(error))
          });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* mobile */
@media screen and (max-width: 600px) {
  .input {
    padding-top: 1em;
  }

  .buttons {
    margin-top: 1em;
  }

  .confirm {
    width: 70vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .input {
    padding-top: 1em;
  }

  .buttons {
    margin-top: 1em;
  }

  .confirm {
    width: 30vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .input {
    padding-top: 1em;
  }

  .buttons {
    margin-top: 1em;
  }

  .confirm {
    width: 30vw;
  }
}
</style>
