import './assets/background.css';
import './assets/schaaktoernooien.css';
import {createApp} from 'vue'
import App from './App.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import router from "@/router";
import {Auth} from "@aws-amplify/auth";
import {Amplify} from '@aws-amplify/core';
import user from "@/components/users";
import {library} from '@fortawesome/fontawesome-svg-core'
import {createI18n} from 'vue-i18n'
import {
    faBars,
    faCheck,
    faCog,
    faCopyright,
    faEnvelope,
    faExclamationCircle,
    faFileContract,
    faHome,
    faLock,
    faPlus,
    faSignInAlt,
    faSignOutAlt,
    faTicketAlt,
    faTimes,
    faTrophy,
    faUniversity,
    faUser,
    faUsers,
    faWrench
} from '@fortawesome/free-solid-svg-icons'
import nl from '@/locale/nl'
import en from '@/locale/en'

library.add(faBars, faCheck, faCog, faCopyright, faEnvelope, faExclamationCircle, faFileContract, faHome, faLock, faPlus, faSignInAlt, faSignOutAlt, faTicketAlt, faTimes, faTrophy, faUniversity, faUser, faUsers, faWrench)

Amplify.configure({
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-west-1_4dkNCWi3c',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '5mlutlen74uooofejsle73174i',

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH',

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'https://schaaktoernooien-nl.auth.eu-west-1.amazoncognito.com',
            scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:8082/signIn',
            redirectSignOut: 'http://localhost:8082',
            responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    },
    API: {
        endpoints: [
            {
                name: "api",
                endpoint: "https://api.schaaktoernooien.nl",
            },
            {
                name: "authenticated-api",
                endpoint: "https://api.schaaktoernooien.nl",
                custom_header: async () => {
                    // return { Authorization : 'token' }
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    return {Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                }
            },
        ]
    }
});

const messages  = {
    nl: nl,
    en: en
}

const i18n = createI18n({
    locale: 'nl',
    messages
})

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .use(i18n)
    .use(user)
    .mount('#app')

