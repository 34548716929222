<template>
  <section class="w3-theme-l2">
    <div v-for="(period, index) in timeControl.periods" :key="index">
      <span v-if="period.moveCount">{{ period.moveCount }} zetten in </span>
      <span v-if="period.duration">
      <span v-if="period.duration.minutes > 0">      {{ period.duration.minutes }}m</span>
      <span v-if="period.duration.seconds > 0">      {{ period.duration.seconds }}s</span>
      </span>
      <span v-if="period.increment > 0">      + {{ period.increment }}s per zet</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimeControl',
  props: {
    timeControl: {
      periods: []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
