export default {
    app: {
        underConstruction: 'This website is under construction.',
        menu: {
            tournaments: 'tournaments',
            clubs: 'clubs',
            chessPlayers: 'players',
            login: 'login',
            logout: 'logout'
        },
        footer: {
            copyright: 'copyright',
            termsAndConditions: 'terms of service',
            privacyStatement: 'privacy statement',
            contact: 'contact'
        }
    },
    dashboard: {
        freeSubscription: 'Sign up for free',
        adText: 'Easy online organizing tool <br/>for club competitions or chess tournaments',
        menu: {
            defaultInfo: 'Find tournaments, search clubs or investigate your favourite players',
            tournamentsInfo: 'An overview of running tournaments and tournament results',
            clubsInfo: 'See wich clubs are organizing tournaments, have a look at their members etc.',
            chessPlayersInfo: 'You can have a view on a players\' results and tournaments played',
        },
        siteExplanation: {
            title: 'Why should I become a member?',
            unregistered: 'As an unregistered user, you can access tournament results as well as some club and player information.' +
                'Free membership offers various advantages:',
            personalPage: {
                title: 'Personal pages',
                text: 'You will get a nice overview of your own results and current and coming tournaments.'
            },
            knsbConnection: {
                title: 'Automatic binding to public Federation accounts',
                text: 'By linking your account to a Federation player account, a tournament oraniser will automatically have the ' +
                    'required registration information, when you register for a tournament through schaaktoernooien.nl.'
            },
            tournamentByes: {
                title: 'Availability',
                text: 'Per round of a tournament, you can set your availability on this site. THe organiser will be informed ' +
                    'instantly and can easily have a new pairing available.'
            },
            notifications: {
                title: 'Notifications',
                text: 'Receive messages when a new pairing has been made available, or after changes to this schedule. This ' +
                'way you are informed up front of your opponent for the coming round.'
            },
            organize: {
                title: 'Organize your own tournament!',
                text: 'Every registered user can create his/her own tournament. Clubs can maintain their internal competition here, ' +
                    'tournament organisations can alo run and register lager tournaments on schaaktoernooien.nl. Are you organising ' +
                    'a fun home round robin? Then you can also quickly generate the playing schedule here.'
            }
        },
    },
    tournaments: {
        search: 'find tournament',
        create: 'new tournament',
        registrations: 'registrations'
    },
    settings: {
        settings: 'settings',
        userSettings: 'user settings',
        username: 'user name',
        notLinked: 'not linked',
        account: 'account',
        linkToAccount: 'link to federation account',
        password: 'password',
        interface: 'interface',
        language: 'langauge',
        theme: 'theme'
    },
    signin: {
        username: 'user name',
        password: 'password',
        forgotPassword: 'forgot your password?',
        createAccount: 'create account'
    },
    clubs: {
        search: 'find club',
        federation: 'federation',
        code: 'code',
    },
    changePassword: 'change password',
    login: 'login'
};
