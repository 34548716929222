import {Auth} from "@aws-amplify/auth";
import {Hub} from "@aws-amplify/core";
import {reactive, readonly} from 'vue'

export const userState = reactive({
    name: "",
    loggedIn: true
})

export const user = readonly(userState)

export default {
    install: () => {
        Auth.currentUserInfo().then(function (user) {
            if (user != null) {
                setUser(user.username)
            } else {
                unsetUser()
            }
        }).catch(function (error) {
            console.log("ERROR = ", JSON.stringify(error))
            unsetUser()
        })
        Hub.listen('auth', (event) => {
            switch (event.payload.event) {
                case 'signIn':
                    setUser(event.payload.data.username)
                    break;
                case 'signUp':
                    break;
                case 'signOut':
                    unsetUser()
                    break;
                case 'signIn_failure':
                    unsetUser()
                    break;
                case 'configured':
                    console.log('the Auth module is configured');
            }
        });
    }
}

function setUser(username) {
    userState.name = username
    userState.loggedIn = true
}

function unsetUser() {
    userState.name = ""
    userState.loggedIn = false
}

