<template>
  <div class="container">
    <club-card v-for="club in clubs" :key="club.federationCode + '#' + club.clubCode" :club="club"></club-card>
  </div>
</template>
<script>
import ClubCard from "@/components/clubs/ClubCard";

export default {
  name: 'ClubsList',
  components: {ClubCard},
  props: {
    clubs: Array,
  },
}
</script>

<style scoped>

a {
  text-decoration: none;
}

.container {
  max-width: 100vw;
  display: grid;
  grid-gap: 1vw;
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}

</style>

