<template>
  <modal class="modalOverride" v-show="true" >
    <template v-slot:body>
      <div class="closeButton">
        <button class="w3-right theme-icon-button" @click="home">
          <font-awesome-icon icon="times" />
        </button>
      </div>
      <div class="w3-container w3-theme-d1 header"><h3>Select your federation account</h3></div>

      <edit-registration />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditRegistration from "@/components/tournaments/registrations/EditRegistration";

export default {
  name: 'SelectUser',
  components: {Modal, EditRegistration},
  methods: {
    home() {
      this.$router.push('/')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.closeButton {
  font-size: 20px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
}

.fieldsContainer {
  position: relative;
  top: -10px;
  display: flex;
  flex-direction: column;
}

.header {
  border-bottom: 2px solid #111d2f;
  font-weight: bold;
}

.input {
  margin-bottom: 10px;
  display: flex; 
  align-items: stretch;
  padding-top: 10px;
}

.preInputImage {
  font-size: 40px;
  padding: 5px;
  border-right-style: hidden !important;
  z-index: 1;
  color: #111d2f  ;
}

.theme-input {
  border-left-style: hidden !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
}

.w3-container {
  margin-bottom: 10px;
}

.w3-btn {
  margin-top: 1em; 
}

.link {
  margin-top: 1em;
}

/* mobile */
@media screen and (max-width: 600px) {
  .fieldsContainer {
    width: 70vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .fieldsContainer {
    width: 300px;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .fieldsContainer {
    width: 300px;
  }
}
</style>
