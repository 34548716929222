<template>
  <div class="container">
    <club-details :federationId="$route.params.federationCode" :clubId="$route.params.clubCode"></club-details>
  </div>
</template>

<script>
import ClubDetails from "@/components/clubs/ClubDetails";

export default {
  name: 'Club',
  components: {ClubDetails},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  max-width: 100vw;
  display: grid;
  grid-gap: 1vw;
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
    padding: 1vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
    padding: 2vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
    padding: 2vw;
  }
}


</style>
