<template>
  <modal  class="modalOverride" v-show="true">
    <template v-slot:body>
      <div class="closeButton">
        <button class="w3-button w3-right theme-icon-button" @click="home">
          <font-awesome-icon icon="times" />
        </button>
      </div>

      <div class="intro">
        <div class="w3-container w3-theme-d1 header"><h3>e-mail bevestiging</h3></div>
        <p>U ontvangt voor het account {{username}} een email waarin een code staat vermeld.
          Voer deze code met uw nieuwe wachtwoord in om uw wachtwoord te wijzigen.</p>
      </div>

      <div class="fieldsContainer">

        <div class="input">
            <div><font-awesome-icon icon="ticket-alt" class="preInputImage"/></div>
            <div style="flex: 1;"><input id="codeField" v-model="codeInput" v-on:keyup.enter="confirm" class="w3-input w3-border theme-input" type="text" placeholder="code" autofocus></div>
        </div>        

        <div class="input">
            <div><font-awesome-icon icon="lock" class="preInputImage"/></div>
            <div style="flex: 1;"><input id="passwordField" v-model="passwordInput" v-on:keyup.enter="confirm" class="w3-input w3-border theme-input" type="password" placeholder="wachtwoord" autofocus></div>
        </div>        

        <ul>
          <li>
            <font-awesome-icon v-if="passwordLengthOk" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            minstens 8 karakters
          </li>
          <li>
            <font-awesome-icon v-if="passwordHasNumber" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            bevat getal
          </li>
          <li>
            <font-awesome-icon v-if="passwordHasUpperCase" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            bevat hoofdletter
          </li>
          <li>
            <font-awesome-icon v-if="passwordHasLowerCase" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            bevat kleine letter
          </li>
        </ul>
      </div>
      <section class="buttons">
        <button class="w3-btn theme-button-dark w3-right" @click="confirm">versturen</button>
      </section>
    </template>
  </modal>

</template>

<script>
import {Auth} from "@aws-amplify/auth";
import Modal from "@/components/Modal";

export default {
  name: 'ResetPassword',
  components: {Modal},
  data: function () {
    return {
      username: this.$route.params.username,
      passwordInput: "",
      codeInput: "",
      errorMessage: "",
    }
  },
  mounted() {
    this.focusInput();
  },
  computed: {
    passwordLengthOk() {
      return this.passwordInput.length >= 8
    },
    passwordHasNumber() {
      return /\d/.test(this.passwordInput);
    },
    passwordHasUpperCase() {
      return /[A-Z]/.test(this.passwordInput);
    },
    passwordHasLowerCase() {
      return /[a-z]/.test(this.passwordInput);
    }
  },
  methods: {
    confirm() {
      let that = this
      Auth.forgotPasswordSubmit(this.username, this.codeInput, this.passwordInput)
          .then(function () {
            that.$router.push('/sign-in')
          })
          .catch(function (error) {
            console.log("ERROR = ", JSON.stringify(error))
          });
    },
    home() {
      this.$router.push('/')
    },
    focusInput() {
      var x = document.getElementById("codeField");
      x.focus();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  border-bottom: 2px solid #111d2f;
}
.closeButton {
  font-size: 20px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
}
.fieldsContainer {
  position: relative;
  top: -10px;
  display: flex;
  flex-direction: column;
}
.input {
  margin-bottom: 10px;
  display: flex; 
  align-items: stretch;
  padding-top: 10px;
}
.preInputImage {
  font-size: 40px;
  padding: 5px;
  border-right-style: hidden !important;
  z-index: 1;
}
.theme-input {
  border-left-style: hidden !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
}
.w3-container {
  margin-bottom: 10px;
}
.w3-btn {
  margin-top: 1em; 
}


/* mobile */
@media screen and (max-width: 600px) {
  .intro {
    width: 80vw;
  }
  .fieldsContainer {
    width: 80vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .intro {
    width: 300px;
  }
  .fieldsContainer {
    width: 300px;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .intro {
    width: 300px;
  }

  .fieldsContainer {
    width: 300px;
  }
}
</style>
