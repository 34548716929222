<template>
  <div class="w3-theme-l5">
    <select v-model="$i18n.locale">
      <option 
        v-for="(lang, i) in langs"
        :@click="localeSelected()"
        :key="`lang-${i}`"
        :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>   
</template>

<script>
export default {
  name: 'SelectLocale',
  data() {
    return { langs: ['nl', 'en'] }
  },
  methods: {
    localeSelected() {
      localStorage.setItem("locale", this.$i18n.locale);
    }
  }
}
</script>