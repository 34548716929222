<template>
  <div class="tournament">
    <header class="w3-container w3-theme-l1">
      <h4>{{ tournament.name }} {{ tournament.edition }}</h4>
    </header>
    <section class="w3-theme-l2 details">
      <section>{{ tournament.location }} {{ tournament.startDate }} - {{ tournament.endDate }}</section>
      <section>{{ tournament.type }}
        <time-control v-if="tournament.timeControl" :timeControl="tournament.timeControl"></time-control>
      </section>
    </section>
  </div>
</template>

<script>
import TimeControl from "@/components/tournaments/TimeControl";
import {RestAPI} from "@aws-amplify/api-rest";

const apiName = 'api';
const path = '/tournaments/';
const myInit = { // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

export default {
  name: 'TournamentDetails',
  components: {TimeControl},
  props: {
    tournamentId: String
  },
  data: function () {
    return {
      tournament: {tournamentId: this.tournamentId, timeControl: {periods: []}}
    }
  },
  beforeMount() {
    let that = this
    let completePath = path + this.$route.params.tournamentId
    RestAPI
        .get(apiName, completePath, myInit)
        .then(response => {
          that.tournament = response
        })
        .catch(error => {
          if (error.status !== 404) {
            console.log("ERROR", JSON.stringify(error));
          }
        });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* mobile */
@media screen and (max-width: 600px) {

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {

  .details {
    padding: 1vw;
  }

}

/* laptop */
@media screen and (min-width: 1281px) {

  .details {
    padding: 1vw;
  }

}

</style>
