<template>
  <modal class="modalOverride" v-show="true">
    <template v-slot:body>
      <div class="closeButton">
        <button class="w3-button w3-right theme-icon-button" @click="home">
          <font-awesome-icon icon="times" />
        </button>
      </div>
      <div class="fieldsContainer">
        <div class="w3-container w3-theme-d1 header"><h3>aanmelden</h3></div>

        <div class="input">
            <div><font-awesome-icon icon="envelope" class="preInputImage"/></div>
            <div style="flex: 1;"><input id="emailField" v-model="emailInput" v-on:keyup.enter="signIn" class="w3-input w3-border theme-input" type="text" placeholder="e-mail" autofocus></div>
        </div>        
        <ul>
          <li>
            <font-awesome-icon v-if="emailValid" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            geldig e-mail adres
          </li>
        </ul>

        <div class="input">
            <div><font-awesome-icon icon="user" class="preInputImage"/></div>
            <div style="flex: 1;"><input v-model="usernameInput" v-on:keyup.enter="signIn" class="w3-input w3-border theme-input" type="text" placeholder="gebruikernaam" autofocus></div>
        </div>        
        <ul>
          <li>
            <font-awesome-icon v-if="usernameLengthOk" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            minstens 4 karakters
          </li>
        </ul>

        <div class="input">
          <div><font-awesome-icon icon="lock" class="preInputImage"/></div>
          <div style="flex: 1;"><input v-model="passwordInput" @change="onChange" v-on:keyup.enter="signIn" class="w3-input w3-border theme-input" type="password" placeholder="wachtwoord"></div>
        </div>
        <ul>
          <li>
            <font-awesome-icon v-if="passwordLengthOk" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            minstens 8 karakters
          </li>
          <li>
            <font-awesome-icon v-if="passwordHasNumber" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            bevat getal
          </li>
          <li>
            <font-awesome-icon v-if="passwordHasUpperCase" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            bevat hoofdletter
          </li>
          <li>
            <font-awesome-icon v-if="passwordHasLowerCase" class="w3-text-green" icon="check"/>
            <font-awesome-icon v-else class="w3-text-red" icon="times"/>
            bevat kleine letter
          </li>
        </ul>
        
        <div v-if="errorMessage" class="w3-panel errorBox w3-round">{{ errorMessage }} <font-awesome-icon icon="exclamation-circle" class="errorIcon w3-right"/></div>
      </div>  

      <div class="buttonContainer">
        <div class="link"><router-link to="/sign-in" class="specialLink">al een account?</router-link></div>
        <div><button class="w3-btn theme-button-dark w3-right" @click="signUp">aanmelden</button></div>
      </div>
    </template>
  </modal>
</template>

<script>
import {Auth} from "@aws-amplify/auth";
import Modal from "@/components/Modal";

export default {
  name: 'SignUp',
  components: {Modal},
  data: function () {
    return {
      emailInput: "",
      usernameInput: "",
      passwordInput: "",
      codeInput: "",
      errorMessage: "",
    }
  },
  mounted() {
    this.focusInput();
  },
  computed: {
    usernameLengthOk() {
      return this.usernameInput.length >= 4
    },
    emailValid() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.emailInput);
    },
    passwordLengthOk() {
      return this.passwordInput.length >= 8
    },
    passwordHasNumber() {
      return /\d/.test(this.passwordInput);
    },
    passwordHasUpperCase() {
      return /[A-Z]/.test(this.passwordInput);
    },
    passwordHasLowerCase() {
      return /[a-z]/.test(this.passwordInput);
    }
  },
  methods: {
    signUp() {
      let that = this
      Auth.signUp({
        username: this.usernameInput,
        password: this.passwordInput,
        attributes: {
          email: this.emailInput
        }
      }).then(function () {
        that.$router.push('/sign-up-confirm/' + encodeURIComponent(that.usernameInput))
      }).catch(function (error) {
        switch (error.name) {
          case "UsernameExistsException":
            that.errorMessage = "naam bestaat al"
            break;
          default:
            that.errorMessage = error.log
        }
      });
    },
    home() {
      this.$router.push('/')
    },
    focusInput() {
      var x = document.getElementById("emailField");
      x.focus();
    },  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  border-bottom: 2px solid #111d2f;
}
.closeButton {
  font-size: 20px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
}
.fieldsContainer {
  position: relative;
  top: -10px;
  display: flex;
  flex-direction: column;
}
.input {
  margin-bottom: 10px;
  display: flex; 
  align-items: stretch;
  padding-top: 10px;
}
.preInputImage {
  font-size: 40px;
  padding: 5px;
  border-right-style: hidden !important;
  z-index: 1;
}
.theme-input {
  border-left-style: hidden !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
}
.w3-container {
  margin-bottom: 10px;
}
ul {
  margin-top: 0;
}
.link{
  position: absolute;
  bottom: 16px;
  padding-left: 5px;
}
/* mobile */
@media screen and (max-width: 600px) {
  .input {
    padding-top: 6px;
  }
  .fieldsContainer {
    width: 70vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .input {
    padding-top: 6px;
  }
  .fieldsContainer {
    width: 300px;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .input {
    padding-top: 6px;
  }

  .fieldsContainer {
    width: 300px;
  }
}
</style>
