<template>
  <router-link :to="'/federations/' + club.federationCode + '/clubs/' + club.clubCode" class="w3-theme-l2">
    <header class="w3-theme-l1">{{ club.name }} </header>
    <div class="details w3-theme-l2">
      <section>{{ $t('clubs.code') }} : {{club.clubCode}}</section>
    </div>
  </router-link>
</template>

<script>

export default {
  name: 'ClubCard',
  components: {},
  props: {
    club: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>


/* mobile */
@media screen and (max-width: 600px) {

  header {
    font-size: 5vw;
    padding: 2vw;
  }

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }

  .details {
    padding: 1vw;
  }

}

/* laptop */
@media screen and (min-width: 1281px) {

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }

  .details {
    padding: 1vw;
  }

}

</style>
