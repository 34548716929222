<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <section class="modal-body">
          <slot name="body">
            I'm the default body!
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",

  methods: {
    close() {
      // this.$parent.cancel();
      this.$emit("close");
    },
    // save() {
    //   this.$parent.save();
    //   this.$emit("close");
    // }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  }

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

/* mobile */
@media screen and (max-width: 600px) {
  button {
    margin: 3vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  button {
    margin: 2vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  button {
    margin: 1vw;
  }
}
</style>
