<template>
  <div class="container">
    <div class="content">
      <header class="w3-theme-l1">
        <h4>toernooi : {{ tournament.name }} {{ tournament.edition }}</h4>
      </header>
      <div class="details w3-theme-l2">
        <label>
          naam
          <input v-model="tournament.name" class="w3-input w3-border theme-input" type="text" placeholder="naam">
        </label>
        <label>
          editie
          <input v-model="tournament.edition" class="w3-input w3-border theme-input" type="text"
                 placeholder="editie">
        </label>
        <label>
          locatie
          <input v-model="tournament.location" class="w3-input w3-border theme-input" type="text"
                 placeholder="locatie">
        </label>
        van
        <datepicker-lite :value="tournament.startDate" @value-changed="startDateSelection" :locale="locale">>
        </datepicker-lite>
        tot
        <datepicker-lite :value="tournament.endDate" @value-changed="endDateSelection" :locale="locale">>
        </datepicker-lite>
        <label>
          indeling
          <select class="w3-select" v-model="tournament.type">
            <option value="swiss">zwitsers</option>
            <option value="keizer">keizer</option>
            <option value="round-robin">volledige competitie</option>
          </select>
        </label>
        <label>
          tempo
          <div class="time-controls">
            <button class="w3-btn theme-button-dark time-control" @click="timeControlKNSB">KNSB</button>
            <button class="w3-btn theme-button-dark time-control" @click="timeControlSGS">SGS</button>
            <button class="w3-btn theme-button-dark time-control" @click="timeControl5">5m</button>
            <button class="w3-btn theme-button-dark time-control" @click="timeControl3and2">3m + 2s</button>
          </div>
          <div>
            <time-control v-if="timeControlExists" :timeControl="tournament.timeControl"></time-control>
          </div>
        </label>
        <div class="buttons">
          <div class="w3-btn theme-button-dark" @click="save">opslaan</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {RestAPI} from "@aws-amplify/api-rest";
import DatepickerLite from 'vue3-datepicker-lite';
import TimeControl from "@/components/tournaments/TimeControl";

const apiName = 'api';
const path = '/tournaments/';
const myInit = { // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

export default {
  name: 'EditTournamentDetails',
  components: {TimeControl, DatepickerLite},
  computed: {
    timeControlExists() {
      return this.tournament.timeControl != null
    }
  },
  data: function () {
    return {
      tournament: {tournamentId: this.$route.params.tournamentId, timeControl: {periods: []}},
      locale: {
        format: "YYYY-MM-DD",
        weekday: ["Z", "M", "D", "W", "D", "V", "Z"],
        todayBtn: "vandaag",
        clearBtn: "verwijderen",
        closeBtn: "sluiten"
      }
    }
  },
  methods: {
    save: function () {
      let that = this
      const apiName = 'authenticated-api';
      const path = '/tournaments/';
      const myInit = { // OPTIONAL
        body: this.tournament,
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      };
      let completePath = path + this.tournament.tournamentId
      RestAPI
          .put(apiName, completePath, myInit)
          .then(() => {
            console.log("THEN:...")
            that.$router.push({
              name: 'Tournament',
              params: {tournamentId: that.tournament.tournamentId},
              query: {mode: 'show'}
            })
          })
          .catch(error => {
            console.log(error.response);
          });
    },
    startDateSelection(value) {
      this.tournament.startDate = value;
    },
    endDateSelection(value) {
      this.tournament.endDate = value;
    },
    timeControlKNSB() {
      this.tournament.timeControl = {
        periods: [
          {moveCount: 40, duration: {minutes: 90}, increment: 30},
          {moveCount: 0, duration: {minutes: 30}, increment: 30}
        ]
      }
    },
    timeControlSGS() {
      this.tournament.timeControl = {
        periods: [{duration: {minutes: 100}, increment: 10}]
      }
    },
    timeControl5() {
      this.tournament.timeControl = {
        periods: [{duration: {minutes: 5}}]
      }
    },
    timeControl3and2() {
      this.tournament.timeControl = {
        periods: [{duration: {minutes: 3}, increment: 2}]
      }
    },
  },
  beforeMount() {
    if (this.$route.query.mode !== 'create') {
      let that = this
      let completePath = path + this.$route.params.tournamentId
      RestAPI
          .get(apiName, completePath, myInit)
          .then(response => {
            that.tournament = response
          })
          .catch(error => {
            if (error.status !== 404) {
              console.log("ERROR", JSON.stringify(error));
            }
          });
    }
  }
}
</script>

<style scoped>

.buttons {
  display: flex;
  flex-direction: row-reverse;
}

.time-controls {
  padding-top: 1vw;
  padding-bottom: 2vw;
  display: flex;
  justify-content: normal;
  gap: 1vw;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* mobile */
@media screen and (max-width: 600px) {

  .content {
    width: 96vw;
  }

  header {
    font-size: 5vw;
    padding: 2vw;
  }

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .content {
    width: 80vw;
  }

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }


  .details {
    padding: 1vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .content {
    width: 40vw;
  }

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }


  .details {
    padding: 1vw;
  }

}
</style>
