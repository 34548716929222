export default {
    app: {
        underConstruction: 'Deze website is "under construction."',
        menu: {
            tournaments: 'toernooien',
            clubs: 'clubs',
            chessPlayers: 'schakers',
            login: 'inloggen',
            logout: 'uitloggen'
        },
        footer: {
            copyright: 'copyright',
            termsAndConditions: 'algemene voorwaarden',
            privacyStatement: 'privacy statement',
            contact: 'contact'
        }
    },
    dashboard: {
        freeSubscription: 'Wordt gratis lid',
        adText: 'Organiseer eenvoudig online <br/>je clubcompetitie of schaaktoernooi',
        menu: {
            defaultInfo: 'Vind toernooien, zoek een club, of bekijk je favoriete spelers',
            tournamentsInfo: 'Een overzicht van lopende toernooien en toernooiuitslagen',
            clubsInfo: 'Bekijk welke clubs er zijn en welke toernooien ze organiseren, wie er spelen etc.',
            chessPlayersInfo: 'Per speler kun je zien welke toernooien zijn gespeeld en wat de uitslagen waren'
        },
        siteExplanation: {
            title: 'Waarom lid worden?',
            unregistered: 'Als ongeregistreerde gebruiker kun je op schaaktoernooien.nl uitslagen bekijken van gespeelde competities. ' +
                'Gratis registreren heeft diverse voordelen:',
            personalPage: {
                title: 'Persoonlijke pagina\'s',
                text: 'Voor een mooi overzicht van je eigen uitslagen en lopende toernooien.'
            },
            knsbConnection: {
                title: 'Koppeling met KNSB gegevens',
                text: 'Door je account te koppelen aan een speleraccount van de KNSB, heeft een organisator direct jouw benodigde ' +
                    'gegevens als je je via schaaktoernooien.nl aanmeldt voor een toernooi.'
            },
            tournamentByes: {
                title: 'Aan- en afmelden',
                text: 'Je kunt je via deze site aanmelden of afmelden voor bepaalde ronden in een toernooi. De organisator is ' +
                    'daarmee automatisch op de hoogte.'
            },
            notifications: {
                title: 'Notificaties',
                text: 'Ontvang berichten als er een indeling is gemaakt of gewijzigd zodat je weet tegen wie je speelt.'
            },
            organize: {
                title: 'Organiseer je eigen toernooi!',
                text: 'Iedere gebruiker kan een eigen toernooi starten. Als club kun je je interne competitie bijhouden, als ' +
                    'toernooiorganisatie kun je ook grote toernooien registreren in dit systeem. Organiseer je thuis een ' +
                    'toernooitje, dan kun je snel het speelschema laten genereren.'
            }
        },
    },
    tournaments: {
        search: 'zoek toernooi',
        create: 'nieuw toernooi',
        registrations: 'aanmeldingen'
    },
    settings: {
        settings: 'instellingen',
        userSettings: 'gebruiker',
        username: 'gebruikernaam',
        notLinked: 'niet gekoppeld',
        account: 'account',
        linkToAccount: 'koppelen aan KNSB account',
        password: 'wachtwoord',
        interface: 'scherm',
        language: 'taal',
        theme: 'thema'
    },
    signin: {
        username: 'gebruikernaam',
        password: 'wachtwoord',
        forgotPassword: 'wachtwoord vergeten?',
        createAccount: 'account aanmaken'
    },
    clubs: {
        search: 'zoek club',
        federation: 'bond',
        code: 'code',
    },
    changePassword: 'wijzig wachtwoord',
    login: 'login'
};
