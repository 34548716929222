<template>
  <router-link :to="/tournaments/ + tournament.tournamentId" class="w3-theme-l2">
    <header class="w3-theme-l1">{{ tournament.name }} </header>
    <div class="details w3-theme-l2">
      <div>editie: {{ tournament.edition }}</div>
      <div>locatie: {{ tournament.location }}</div>
      <div>periode: {{ tournament.startDate }} - {{ tournament.endDate }}</div>
      <div>indeling: {{ tournament.type }}
        <time-control :timeControl="tournament.timeControl"></time-control>
      </div>
    </div>
  </router-link>
</template>

<script>
import TimeControl from "@/components/tournaments/TimeControl";

export default {
  name: 'TournamentCard',
  components: {TimeControl},
  props: {
    tournament: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>


/* mobile */
@media screen and (max-width: 600px) {

  header {
    font-size: 5vw;
    padding: 2vw;
  }

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }

  .details {
    padding: 1vw;
  }

}

/* laptop */
@media screen and (min-width: 1281px) {

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }

  .details {
    padding: 1vw;
  }

}

</style>
