<template>
  <modal class="modalOverride" v-show="true">
    <template v-slot:body>
      <div class="change-password">
        <div class="w3-container w3-blue-gray"><h3>wijzig wachtwoord voor {{ username }}</h3></div>
      </div>
      <div class="input">
        <label class="w3-text-blue-gray"><b>oud wachtwoord</b>
          <input v-model="oldPasswordInput" @change="onChange" class="w3-input w3-border" type="password">
        </label>
      </div>
      <div class="input">
        <label class="w3-text-blue-gray"><b>nieuw wachtwoord</b>
          <input v-model="passwordInput" @change="onChange" class="w3-input w3-border" type="password">
        </label>
      </div>
      <ul>
        <li>
          <font-awesome-icon v-if="passwordLengthOk" class="w3-text-green" icon="check"/>
          <font-awesome-icon v-else class="w3-text-red" icon="times"/>
          minstens 8 karakters
        </li>
        <li>
          <font-awesome-icon v-if="passwordHasNumber" class="w3-text-green" icon="check"/>
          <font-awesome-icon v-else class="w3-text-red" icon="times"/>
          bevat getal
        </li>
        <li>
          <font-awesome-icon v-if="passwordHasUpperCase" class="w3-text-green" icon="check"/>
          <font-awesome-icon v-else class="w3-text-red" icon="times"/>
          bevat hoofdletter
        </li>
        <li>
          <font-awesome-icon v-if="passwordHasLowerCase" class="w3-text-green" icon="check"/>
          <font-awesome-icon v-else class="w3-text-red" icon="times"/>
          bevat kleine letter
        </li>
      </ul>
      <section class="buttons">
        <button class="w3-btn w3-border w3-border-blue-gray w3-hover-blue-gray" @click="home">terug</button>
        <button class="w3-btn w3-blue-gray w3-right" @click="confirm">versturen</button>
      </section>
    </template>
  </modal>

</template>

<script>
import {Auth} from "@aws-amplify/auth";
import Modal from "@/components/Modal";

export default {
  name: 'ChangePassword',
  components: {Modal},
  data: function () {
    return {
      username: this.$route.params.username,
      oldPasswordInput: "",
      passwordInput: "",
      errorMessage: "",
    }
  },
  computed: {
    passwordLengthOk() {
      return this.passwordInput.length >= 8
    },
    passwordHasNumber() {
      return /\d/.test(this.passwordInput);
    },
    passwordHasUpperCase() {
      return /[A-Z]/.test(this.passwordInput);
    },
    passwordHasLowerCase() {
      return /[a-z]/.test(this.passwordInput);
    }
  },
  methods: {
    home() {
      this.$router.push('/')
    },
    confirm() {
      let that = this
      Auth.currentAuthenticatedUser()
          .then(user => changePassword(that, user))
          .then(rerouteToLogin(that))
          .catch(failureCallBack)
    },
  }
}

function failureCallBack(error) {
  console.log("error in flow = ", JSON.stringify(error))
}

function changePassword(that, user) {
  Auth.changePassword(user, that.oldPasswordInput, that.passwordInput)
}

function rerouteToLogin(that) {
  that.$router.push('/sign-in')
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* mobile */
@media screen and (max-width: 600px) {
  .input {
    padding-top: 1em;
  }

  .buttons {
    margin-top: 1em;
  }

  .confirm {
    width: 70vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .input {
    padding-top: 1em;
  }

  .buttons {
    margin-top: 1em;
  }

  .confirm {
    width: 30vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .input {
    padding-top: 1em;
  }

  .buttons {
    margin-top: 1em;
  }

  .confirm {
    width: 30vw;
  }
}
</style>
