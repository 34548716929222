<template>
  <div class="w3-theme-l5">
    <select v-model="currentTheme">
      <option 
        v-for="(theme, i) in themes"
        :key="`theme-${i}`"
        :value="theme">
        {{ theme }}
      </option>
    </select>
  </div>   
</template>

<script>
var previousTheme = "theme_blue";

export default {
  name: 'SelectTheme',
  data() {
    return { 
      themes: ['theme_blue', 'theme_dark', 'theme_flat', "theme_white"],
      currentTheme: "theme_blue",
      previousTheme: "theme_blue",
    }
  },
  mounted() {
    this.determineTheme();
  },
  methods: {
    determineTheme() {
      var theme = localStorage.getItem("theme");
      if (theme != null && theme != "undefined") {
        this.currentTheme = theme;
        previousTheme = theme;
      }
    }
  },
  watch: {
    currentTheme: function () {
      localStorage.setItem("theme", this.currentTheme);
      document.body.classList.replace(previousTheme, this.currentTheme); 
      previousTheme = this.currentTheme;
    }
  }
}
</script>