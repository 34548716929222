<template>
  <div class="container" >
    <div class="header"><h2>login</h2></div>
    <div class="fieldsContainer">
      <div class="closeButton">
        <button class="btn theme-close-button" @click="home">
          <font-awesome-icon icon="times" />
        </button>
      </div>
      <div class="input">
          <div style="flex: 1;"><input id="nameField" v-model="usernameInput" v-on:keyup.enter="signIn" class="themeInput" type="text" :placeholder="$t('signin.username')" autofocus></div>
          <div><font-awesome-icon icon="user" class="inputImage"/></div>
      </div>        

      <div class="input">
        <div style="flex: 1;"><input v-model="passwordInput" v-on:keyup.enter="signIn" class="themeInput" type="password" :placeholder="$t('signin.password')"></div>
        <div><font-awesome-icon icon="lock" class="inputImage"/></div>
      </div>
      
      <div v-if="errorMessage" class="w3-panel errorBox w3-round">{{ errorMessage }} <font-awesome-icon icon="exclamation-circle" class="errorIcon w3-right"/></div>
    </div>
    
    <section>
      <button class="button-default" @click="signIn">{{ $t('app.menu.login') }}</button>
    </section>
    <section class="link">
      <router-link to="/reset-password-request" class="specialLink">{{ $t('signin.forgotPassword') }}</router-link>
      <br/>
      <router-link to="/sign-up" class="specialLink">{{ $t('signin.createAccount') }}</router-link>
    </section>
  </div>
</template>

<script>
import {Auth} from "@aws-amplify/auth";
import {user} from "@/components/users";

export default {
  name: 'SignIn',
  data: function () {
    return {
      usernameInput: "",
      passwordInput: "",
      errorMessage: "",
    }
  },
  mounted() {
    this.focusInput();
  },
  computed: {
    username: function () {
      return user.name
    },
    loggedIn: function () {
      return user.loggedIn
    }
  },
  methods: {
    signIn() {
      let that = this
      Auth.signIn({username: this.usernameInput, password: this.passwordInput})
          .then(function () {
            that.$router.push('/')
          })
          .catch(function (error) {
            switch (error.name) {
              case "InvalidParameterException":
              case "AuthError":
              case "UserNotFoundException":
              case "NotAuthorizedException":
                that.errorMessage = "ongeldige combinatie"
                break;
              default:
                that.errorMessage = error.log
            }
          })
    },
    home() {
      this.$router.push('/')
    },
    focusInput() {
      var x = document.getElementById("nameField");
      x.focus();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.closeButton {
  font-size: 20px;
  position: absolute;
  z-index: 2;
  right: -30px;
  top: -30px;
}

.container {
  margin: auto;
  margin-top: 20vh;
}

.fieldsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.header {
  font-weight: bold;
}

.theme_flat .header {
  display: none;
}

.input {
  margin-bottom: 10px;
  display: flex; 
  align-items: stretch;
  padding-top: 10px;
  position: relative;
}

.inputImage {
  font-size: 32px;
  padding: 5px;
  border-style: hidden;
  z-index: 1;
  position: absolute;
}

.link {
  margin-top: 1em;
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
    margin: 5vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
    width: 300px;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
    width: 300px;
  }
}
</style>
