<template>
  <div class="tournament">
    <header class="w3-container w3-theme-l1">
      <h4>{{ club.name }}</h4>
    </header>
    <section class="w3-theme-l2 details">
      <section>{{ $t('clubs.federation') }} : {{club.federationCode}}</section>
      <section>{{ $t('clubs.code') }} : {{club.clubCode}}</section>
    </section>
  </div>
</template>

<script>
import {RestAPI} from "@aws-amplify/api-rest";

const apiName = 'api';
const myInit = { // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

export default {
  name: 'ClubDetails',
  components: {},
  props: {
    federationCode: String,
    clubCode: String
  },
  data: function () {
    return {
      club: {}
    }
  },
  beforeMount() {
    let that = this
    let path = '/federations/' + this.$route.params.federationCode + '/clubs/' + this.$route.params.clubCode
    RestAPI
        .get(apiName, path, myInit)
        .then(response => {
          that.club = response
        })
        .catch(error => {
          if (error.status !== 404) {
            console.log("ERROR", JSON.stringify(error));
          }
        });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* mobile */
@media screen and (max-width: 600px) {

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {

  .details {
    padding: 1vw;
  }

}

/* laptop */
@media screen and (min-width: 1281px) {

  .details {
    padding: 1vw;
  }

}

</style>
