<template>
  <modal  class="modalOverride" v-show="true">
    <template v-slot:body>
      <div class="closeButton">
        <button class="w3-button w3-right theme-icon-button" @click="home">
          <font-awesome-icon icon="times" />
        </button>
      </div>

      <div class="fieldsContainer">
        <div class="w3-container w3-theme-d1 header"><h3>wachtwoord vergeten</h3></div>

        <div class="input">
            <div><font-awesome-icon icon="user" class="preInputImage"/></div>
            <div style="flex: 1;"><input id="nameField" v-model="usernameInput" v-on:keyup.enter="resetPassword" class="w3-input w3-border theme-input" type="text" placeholder="gebruikernaam" autofocus></div>
        </div>        
        
        <div v-if="errorMessage" class="w3-panel errorBox w3-round">{{ errorMessage }} <font-awesome-icon icon="exclamation-circle" class="errorIcon w3-right"/></div>
      </div>
      <section>
        <button class="w3-btn theme-button-dark w3-right" @click="resetPassword">nieuw wachtwoord</button>
      </section>
    </template>
  </modal>

</template>

<script>
import {Auth} from "@aws-amplify/auth";
import Modal from "@/components/Modal";
import {user} from "@/components/users";

export default {
  name: 'ResetPasswordRequest',
  components: {Modal},
  data: function () {
    return {
      usernameInput: "",
      errorMessage: "",
    }
  },
  mounted() {
    this.focusInput();
  },
  computed: {
    username: function () {
      return user.name
    },
  },
  methods: {
    resetPassword() {
      let that = this
      console.log (this.usernameInput)
      Auth.forgotPassword(this.usernameInput)
          .then(function () {
            that.$router.push('/reset-password/' + encodeURIComponent(that.usernameInput))
          })
          .catch(function (error) {
            switch (error.name) {
              default:
                that.errorMessage = error.log
            }
          })
    },
    home() {
      this.$router.push('/')
    },
    focusInput() {
      var x = document.getElementById("nameField");
      x.focus();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  border-bottom: 2px solid #111d2f;
}
.closeButton {
  font-size: 20px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
}
.fieldsContainer {
  position: relative;
  top: -10px;
  display: flex;
  flex-direction: column;
}
.input {
  margin-bottom: 10px;
  display: flex; 
  align-items: stretch;
  padding-top: 10px;
}
.preInputImage {
  font-size: 40px;
  padding: 5px;
  border-right-style: hidden !important;
  z-index: 1;
}
.theme-input {
  border-left-style: hidden !important;
  border-top-style: hidden !important;
  border-right-style: hidden !important;
}
.w3-container {
  margin-bottom: 10px;
}
.w3-btn {
  margin-top: 1em; 
}

/* mobile */
@media screen and (max-width: 600px) {
  .fieldsContainer {
    width: 70vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .fieldsContainer {
    width: 300px;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .fieldsContainer {
    width: 300px;
  }
}
</style>
