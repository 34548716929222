<template>
  <div class="person">
    <p>Person details.</p>
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
