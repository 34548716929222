<template>
  <div class="container">
    <header>
      <input type="text" v-model="searchString" :placeholder="$t('clubs.search')">
    </header>
    <clubs-list :clubs="clubs"></clubs-list>
  </div>
</template>

<script>
import {user} from "@/components/users";
import ClubsList from "@/components/clubs/ClubsList";
import {RestAPI} from "@aws-amplify/api-rest";

const apiName = 'api';
const listPath = '/list?scope=club&forward=true&limit=25';
const searchPath = '/search?scope=club&searchString=';
const myInit = { // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};


export default {
  name: 'ClubsDashboard',
  components: {ClubsList},
  data: function () {
    return {
      searchString: "",
      clubs: [],
      lastKey: "",
    }
  },
  computed: {
    loggedIn() {
      return user.loggedIn
    }
  },
  beforeMount() {
    let that = this
    RestAPI
        .get(apiName, listPath, myInit)
        .then(response => {
          that.clubs = response.items
          that.lastKey = response.lastKey
        })
        .catch(error => {
          console.log(error.response);
        });
  },
  watch: {
    searchString: function () {
      let that = this
      if (!this.searchString) {
        RestAPI
            .get(apiName, listPath, myInit)
            .then(response => {
              that.clubs = response.items
            })
            .catch(error => {
              console.log(error.response);
            });
      } else {
        let fullPath = searchPath + encodeURIComponent(this.searchString);
        RestAPI
            .get(apiName, fullPath, myInit)
            .then(response => {
              that.clubs = response.items
            })
            .catch(error => {
              console.log(error.response);
            });
      }
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

header {
  display: flex;
  justify-content: space-between;
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
    padding: 1vw;
  }

  input {
    width: 50vw;
  }

  header {
    padding: 1vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
    padding: 1vw;
  }

  input {
    width: 30vw;
  }

  header {
    padding: 1vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
    padding: 1vw;
  }

  input {
    width: 30vw;
  }

  header {
    padding: 1vw;
  }
}


</style>
