<template>
  <div class="screenView" v-bind:class="getBackground()">
    <div class="bar" v-if="!modal">

      <div v-if="!isHomepage || loggedIn" class="show-mobile dropdown-click">
        <div id="hamburger" class="bar-item hamburger" @click="click">
          <font-awesome-icon icon="bars"/>
        </div>
        <div id="dropdownMenu" class="w3-dropdown-content bar-block" @click="click">
          <router-link to="/" class="bar-item">
            <font-awesome-icon icon="home"/>
          </router-link>
          <router-link to="/tournaments" class="bar-item">{{ l('menu.tournaments') }}</router-link>
          <router-link to="/clubs" class="bar-item">{{ l('menu.clubs') }}</router-link>
          <router-link to="/persons" class="bar-item">{{ l('menu.chessPlayers') }}</router-link>
        </div>
      </div>

      <div v-if="!isHomepage || loggedIn" class="show-medium">
        <router-link to="/" class="bar-item">
          <font-awesome-icon icon="home"/>
        </router-link>
        <router-link to="/tournaments" class="bar-item">{{ l('menu.tournaments') }}</router-link>
        <router-link to="/clubs" class="bar-item">{{ l('menu.clubs') }}</router-link>
        <router-link to="/persons" class="bar-item">{{ l('menu.chessPlayers') }}</router-link>
      </div>

      <div v-if="!isHomepage || loggedIn" class="show-large">
        <router-link to="/" class="bar-item">
          <font-awesome-icon icon="home"/>
        </router-link>
        <router-link to="/tournaments" class="bar-item">{{ l('menu.tournaments') }}</router-link>
        <router-link to="/clubs" class="bar-item">{{ l('menu.clubs') }}</router-link>
        <router-link to="/persons" class="bar-item">{{ l('menu.chessPlayers') }}</router-link>
      </div>

      <div class="rightMenu">
        <img id="nl" class="countryFlag bar-item" src="./locale/flags/NL.svg" @click="setLang('nl')" />
        <img id="en" class="countryFlag bar-item" src="./locale/flags/GB-UKM.svg" @click="setLang('en')" />
        <user></user>
      </div>
    </div>

    <div class="siteMessage errorBox">
      <font-awesome-icon icon="wrench" class="errorIcon"/>
      {{ l('underConstruction') }}
    </div>

    <div class="mainView">
      <router-view></router-view>
    </div>

    <div class="bar footer" v-if="!modal">
      <router-link to="/copyright" class="footerLink">
        <font-awesome-icon icon="copyright"/>
        {{ l('footer.copyright') }}
      </router-link>
      -
      <router-link to="/terms-of-service" class="footerLink">
        <font-awesome-icon icon="file-contract"/>
        {{ l('footer.termsAndConditions') }}
      </router-link>
      -
      <router-link to="/privacy-statement" class="footerLink">
        <font-awesome-icon icon="lock"/>
        {{ l('footer.privacyStatement') }}
      </router-link>
      -
      <router-link to="/contact" class="footerLink">
        <font-awesome-icon icon="envelope"/>
        {{ l('footer.contact') }}
      </router-link>
    </div>
  </div>
</template>

<script>

import User from "@/components/users/User";
import {user} from "@/components/users";

export default {
  name: 'App',
  components: {User}, 
  mounted() {
    this.determineUserLocale();
    this.determineTheme();
  },
  methods: {
    click() {
      var x = document.getElementById("dropdownMenu");
      if (x.className.indexOf("show") === -1) {
        x.className += " show";
      } else {
        x.className = x.className.replace(" show", "");
      }
    },
    determineTheme() {
      var theme = localStorage.getItem("theme");
      console.log('theme uit localstorage: ' + theme);
      if (theme === null || theme === "undefined") {
        theme = "theme_flat";
      }
      this.setTheme(theme);
    },
    determineUserLocale() {
      var lang = localStorage.getItem("locale");
      console.log('locale uit localstorage: ' + lang);
      if (lang === null) {
        lang  = this.getLocale();
        console.log('locale uit browser: ' + lang);
      }
      this.setLang(lang);
    },
    getBackground() {
      if (this.modal) {
        return 'modalBackground';
      } else {
        return 'normalBackground';
      }
    },
    getLocale() {
      return this.getNavigatorLanguage().substring(0,2);
    },
    getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'nl';
      }
    },
    l(text) {
      return this.$t('app.' + text)
    },
    setLang(lang) {
      var x = document.getElementById(lang);
      x.className = x.className.replace(" inactive", "");
      x.className = x.className.replace(" bar-item", "");
      this.$i18n.locale = lang; 
      
      if (lang==="en") {
        lang = "nl";
      } else {
        lang = "en";
      }
      x = document.getElementById(lang);
      x.className += " inactive bar-item";
    },
    setTheme(theme) {
      document.body.classList.replace("theme_blue", theme); 
    },
  },
  computed: {
    isHomepage: function () {
      return this.$route.path === '/';
    },
    loggedIn: function () {
      return user.loggedIn
    },
    modal: function () {
      return (this.$route.path === '/sign-up') || (this.$route.path === '/sign-in');
    }
  }
}

/* Procedure to close the dropdown menu when user clicked outside of the menu */
document.addEventListener("click", (evt) => {
  const flyoutEl = document.getElementById("dropdownMenu");
  const hamburgerEl = document.getElementById("hamburger");
  let targetEl = evt.target; // clicked element
  do {
    if ((targetEl === flyoutEl) || (targetEl === hamburgerEl)) {
      return; // This is a click inside, does nothing, just return.
    }
    targetEl = targetEl.parentNode; // Go up the DOM
  } while (targetEl);

  // This is a click outside.
  if (flyoutEl.className.indexOf("show") !== -1) {
    flyoutEl.className = flyoutEl.className.replace(" show", "");
  }
});
</script>

<style scoped>
.countryFlag {
  padding: 4px;
}

.dropdown-click {
  position: relative;
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 5px;
  flex-wrap: wrap;
}

.footerLink {
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
  White-space: nowrap;
}

.footerLink:hover {
  text-decoration: underline;
}

.hamburger:focus {
  outline: none;
  background-color: #fff !important;
}

.hamburger:hover {
  color: #eef2f9 !important;
  background-color: #111d2f !important;
}

.inactive {
  opacity: 0.4;  
}

.inactive:hover {
  opacity: 1;  
}

.mainView {
  padding-top: 50px;
  flex-grow: 1;
}

.rightMenu {
  display: flex;
  flex-direction: row;
  float: right;
}

.screenView {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.siteMessage {
  margin: auto;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

/* mobile */
@media screen and (max-width: 600px) {
  .show-mobile {
    display: block;
  }

  .show-medium {
    display: none;
  }

  .show-large {
    display: none;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .show-mobile {
    display: none;
  }

  .show-medium {
    display: block;
  }

  .show-large {
    display: none;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .theme_flat .normalBackground {
    width: 70vw;
    margin: auto;
  }

  .modalBackground {
    width: 100%;
  }

  .show-mobile {
    display: none;
  }

  .show-medium {
    display: none;
  }

  .show-large {
    display: block;
  }
}
</style>