<template>
  <div class="container">
    <tournament-card v-for="tournament in tournaments" :key="tournament.tournamentId" :tournament="tournament"></tournament-card>
  </div>
</template>
<script>
import TournamentCard from "@/components/tournaments/TournamentCard";

export default {
  name: 'TournamentsList',
  components: {TournamentCard},
  props: {
    tournaments: Array,
  },
}
</script>

<style scoped>

a {
  text-decoration: none;
}

.container {
  max-width: 100vw;
  display: grid;
  grid-gap: 1vw;
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}

</style>

