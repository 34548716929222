<template>
  <div v-if="!loggedIn" class="dashView">
    <div class="screenWrapper">
      <div class="adArea">
        <p class="adText" v-html="l('adText')"></p>
        <router-link to="/sign-up" id="btnregistratie" class="button button-succes">{{ l('freeSubscription') }}
        </router-link>
      </div>

      <div class="actionButtonArea">
        <router-link to="/tournaments" class="button circleButton"
                     @mouseover="mouseOver('tournaments')"
                     @mouseleave="mouseOver('default')">
          <font-awesome-icon icon="trophy" class="iconLarge"/>
          <br/>{{ $t('app.menu.tournaments') }}
        </router-link>

        <router-link to="/clubs" class="button circleButton"
                     @mouseover="mouseOver('clubs')"
                     @mouseleave="mouseOver('default')">
          <font-awesome-icon icon="university" class="iconLarge"/>
          <br/>{{ $t('app.menu.clubs') }}
        </router-link>

        <router-link to="/persons" class="button circleButton"
                     @mouseover="mouseOver('persons')"
                     @mouseleave="mouseOver('default')">
          <font-awesome-icon icon="users" class="iconLarge"/>
          <br/>{{ $t('app.menu.chessPlayers') }}
        </router-link>

        </div>
      <div id="subText" class="subText">{{ l('menu.defaultInfo') }}</div>
    </div>

    <div class="siteExplanation">
      <div class="userBenefits w3-theme-l4">
        <font-awesome-icon icon="user" class="iconMedium w3-right"/>
        <h2>{{ l('siteExplanation.title') }}</h2>
        <p>{{ l('siteExplanation.unregistered') }}</p>
        <ul>
          <li><h4>{{ l('siteExplanation.personalPage.title') }}</h4>
            {{ l('siteExplanation.personalPage.text') }}
          </li>
          <li><h4>{{ l('siteExplanation.knsbConnection.title') }}</h4>
            {{ l('siteExplanation.knsbConnection.text') }}
          </li>
          <li><h4>{{ l('siteExplanation.tournamentByes.title') }}</h4>
            {{ l('siteExplanation.tournamentByes.text') }}
          </li>
          <li><h4>{{ l('siteExplanation.notifications.title') }}</h4>
            {{ l('siteExplanation.notifications.text') }}
          </li>
          <li><h4>{{ l('siteExplanation.organize.title') }}</h4>
            {{ l('siteExplanation.organize.text') }}
          </li>
        </ul>
        <router-link to="/sign-up" class="w3-button w3-right w3-round theme-button-express">{{ l('freeSubscription') }}
        </router-link>
        <p>&nbsp;</p>
      </div>
    </div>
  </div> <!-- v-if="!loggedIn" -->

  <div v-if="loggedIn" class="subText">
    <div>Gebruiker is ingelogd. Er komt hier een gepersonaliseerd dashboard. Menuitems zijn nu zichtbaar.
    </div>
  </div>
</template>

<script>

import {user} from "@/components/users";

export default {
  name: 'Dashboard',
  components: {},
  computed: {
    loggedIn: function () {
      return user.loggedIn
    }
  },
  methods: {
    mouseOver(element) {
      var text = ""
      switch (element) {
        case "tournaments":
          text = this.l('menu.tournamentsInfo')
          break;
        case "clubs":
          text = this.l('menu.clubsInfo');
          break;
        case "persons":
          text = this.l('menu.chessPlayersInfo');
          break;
        default:
          text = this.l('menu.defaultInfo')
      }
      var x = document.getElementById("subText");
      x.innerText = text;
    },
    l(text) {
      return this.$t('dashboard.' + text)
    }
  }
}
</script>

<style scoped>
.actionButtonArea {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
    transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.actionButtonArea .circleButton:hover {
  color: #F1C40F; /* Sunflower */
  background-color: inherit;
}

.adArea {
  height: 40vh;
}

.screenWrapper {
  height: 90vh;
}

.siteExplanation {
  padding-bottom: 60px;
  padding-top: 60px;
  background-color: #fff;
}

.userBenefits {
  width: 90vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-bottom: 20px;
}

/* mobile */
@media screen and (max-width: 600px) {
  .adText {
    font-size: 20px;
    text-align: right;
    padding: 20px;
    margin: 10px 0;
  }

  #btnregistratie {
    font-size: 14px;
    margin: 0px 20px 0px 0px;
    float: right;
  }

  .circleButton {
    font-size: 16px;
    width: 100px;
    height: 100px;
    margin: 0px;
    border-radius: 50%;
  }

  .iconLarge {
    font-size: 28px;
    margin-top: 15px;
  }

  .iconMedium {
    font-size: 36px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .subText {
    width: 100%;
    text-align: center;
    margin: 10px;
    padding: 20px;
  }

  .userBenefits {
    width: 90vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .adText {
    font-size: 3vw;
    text-align: right;
    padding: 10px;
    margin: 10px 0;
  }

  #btnregistratie {
    font-size: 20px;
    margin: 10px;
    float: right;
  }

  .circleButton {
    font-size: 24px;
    width: 150px;
    height: 150px;
    top: 50%;
    left: 50%;
    margin: 10px;
    border-radius: 50%;
  }

  .iconLarge {
    font-size: 50px;
    margin-top: 25px;
  }

  .iconMedium {
    font-size: 44px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .subText {
    width: 100%;
    text-align: center;
    margin: 10px;
    padding: 20px;
  }

  .userBenefits {
    max-width: 650px;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .adText {
    font-size: 36px;
    text-align: right;
    padding: 20px;
    margin: 10px 0;
  }

  #btnregistratie {
    font-size: 20px;
    margin: 20px;
    float: right;
  }

  .circleButton {
    font-size: 28px;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 10px;
    border-radius: 50%;
  }

  .iconLarge {
    font-size: 54px;
    margin-top: 30px;
  }

  .iconMedium {
    font-size: 44px;
    margin-top: 10px;
    margin-right: 10px;
  }

  .subText {
    font-size: 20px;
    width: 100%;
    text-align: center;
    margin: 10px;
    padding: 20px;
  }

  .userBenefits {
    max-width: 650px;
  }
}

</style>
