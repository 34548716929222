<template>
  <div class="termsofservice infoPage">
    <div class="infoHeader">
      <font-awesome-icon icon="file-contract" class="w3-right headerIcon" /><h2>algemene voorwaarden</h2>
    </div>
    <div class="infoText">   
      <p>Schaaktoernooien.nl wordt gemaakt door enthousiastelingen en gratis ter beschikking gesteld aan haar gebruikers. 
        Aan gebruik van deze website kunnen geen rechten worden ontleend.</p>
      <h6>Een account registreren</h6>
        Voor het inzien van toernooiinformatie (deelnemers, uitslagen etc.) hoeft geen gebruikeraccount te worden aangemaakt. 
        Om zelf toernooien te kunnen organiseren, dient een gebruikeraccount te worden aagemaakt.
        <br/>
        Om een account aan te maken op schaaktoernooien.nl moet een gebruikersnaam en een wachtwoord worden verzonnen. Er dient een 
        geldig e-mailadres te worden opgegeven, dat met een bericht zal worden geverifiëerd. Het is ook mogelijk om je account te 
        koppelen aan een lidmaatschapsnummer van een schaakinstantie (zoals een nationale bond), waardoor benodigde gegevens van 
        de gebruiker automatisch bekend zijn bij een Organisator. Er zijn twee stricte regels bij het registreren voor een account:
        <ul>
          <li>Het is niet toegestaan om beledigende gebruikersnamen aan te maken.</li>
          <li>Het is de Gebruiker niet toegestaan om zich voor te doen als iemand anders.</li> 
        </ul>
        De Eigenaar behoudt zich het recht voor om naar eigen inzicht, zonder waarschuwing accounts te verwijderen op basis van deze regels. 
        <br/>
        Waneer in deze voorwaarden de term "Gebruiker" of "Gebruikers" wordt gebruikt, dan wordt verwezen naar zowel geregistreerde- als 
        ongeregistreerde gebruikers, tenzij het zaken betreft die alleen kunnen worden uitgevoerd door geregistreerde gebruikers.
      <h6>Gebruiker rechten</h6>
        Gebruikers mogen er vanuit gaan dat er zorgvoldig met hun gegevens wordt omgegaan. Voor verdere uitweiding hierover wordt
        verwezen naar de <router-link to="/persons">Algemene voorwaarden.</router-link>.
        Het staat Gebruikers vrij om zelf nieuwe toernooien aan te maken. Dit kan zijn voor eigen gebruik, ten behoeve van een 
        Clubcompetitie, of ten behoeve van een officieel open toernooi. Ook voor het aanmaken van toernooien zijn spelregels opgesteld:
        <ul>
          <li>Het is niet toegestaan om beledigende toernooinamen aan te maken.</li>
          <li>Het is niet toegestaan om misleidende toernooinamen aan te maken / zich voor te doen als een andere toernooiorganisatie. </li> 
          <li>Het is niet toegestaan om fictieve toernooispelers toe te voegen.</li>
          <li>Het is niet toegestaan om andere spelernamen te gebruiken dan die van de werkelijk spelende spelers.</li>
        </ul>
        De Eigenaar behoudt zich het recht voor om naar eigen inzicht, zonder waarschuwing accounts te verwijderen op basis van
        deze regels. 
        <br/> 
        Gebruikers zijn zelf verantwoordelijk voor de content die zij onder hun account genereren.
      <h6>Richtlijnen</h6>
        Met het aanmaken van een account, gaat de Gebruiker impliciet akkoord met deze Algemene voorwaarden.
        <br/>
        Het is niet toegestaan aanstootgevende, insenuerende, provocatieve of beledigende content te genereren. Beoordeling hiervan 
        ligt bij de Eigenaar van de website. Misbruik zal zonder meer leiden tot het verwijderen van het account.
        <br/>
        Het is niet toegestaan om, op wat voor manier dan ook, te proberen misbruik te maken van het systeem, danwel toegang
        te krijgen tot het systeem of andermans account. Hieronder vallen onder andere (maar niet uitsluitend) DDoS, hacking of gewoon 
        het raden van andermans wachtwoord.
        <br/>
      <h6>Een account opzeggen</h6>
        Een Gebruiker kan zijn of haar account opzeggen via de persoonlijke instellingen pagina. Daarmee vervalt het account en zal
        de gebruiker niet meer via Schaaktoernooien.nl kunnen deelnemen aan toernooien. Geregistreerde uitslagen door én van de Gebruiker blijven 
        bewaard en zullen ook na opheffing van het account openbaar blijven.
      <h6>Rechten van de Eigenaar</h6>
        De Eigenaar mag wijzigingen doorvoeren aan de Website zonder dit vooraf aan Gebruikers te laten weten. De Eigenaar is niet
        aansprakelijk voor enige vorm van schade die hieruit voortvloeit. 
        <br/>
        De Eigenaar kan zelfstandig, naar eigen inzicht handelen in het geval van overtredingen van de spelregels zoals hierboven
        genoemd, of onvoorziene gevallen die, naar het inzicht van de Eigenaar, sancties vereisen.
        <br/>
        Hoewel de Eigenaar gedreven is om een goede beschikbaarheid te garanderen, kan het voorkomen dat de Website niet beschikbaar is.
        De Eigenaar is niet aansprakelijk voor enige vorm van schade die hieruit voortvloeit. 
        <br/>
        De Eigenaar behoudt zich het recht voor om deze voorwaarden aan te passen.
        <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
