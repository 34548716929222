<template>
  <div class="privacyStatement infoPage">
    <div class="infoHeader">
      <font-awesome-icon icon="lock" class="w3-right headerIcon" /><h2>privacy statement</h2>
    </div>
    <div class="infoText">   
      <p>Privacy statement van Marcel Schröer (hierna: de Eigenaar)
      </p>
      <h6>Introductie</h6>
      <p>De Eigenaar is de beheerder van de website schaaktoernooien.nl.
      In dit statement wordt aangegeven hoe de Eigenaar persoonsgegevens verzamelt en op welke wijze deze gegevens worden gebruikt. De verantwoordelijke partij voor de verwerking is de Eigenaar.
      De Eigenaar zet zich in voor de bescherming van de privacy van gebruikers van haar website. Persoonsgegevens van gebruikers worden uiterst zorgvuldig verwerkt en beveiligd. De Eigenaar houdt zich aan de wet en regelgeving op het gebied van de bescherming van persoonsgegevens, zoals de Wet bescherming persoonsgegevens en – met ingang van 25 mei 2018 – de Algemene Verordening Gegevensbescherming (AVG).
      </p>
      <h6>Welke gegevens worden verzameld?</h6>
      <p>Algemene gegevens met betrekking tot bezoeken aan de website van de Eigenaar worden verzameld zonder dat de bezoekers worden geïdentificeerd. Deze gegevens omvatten tevens het IP-adres van de bezoeker. Het IP-adres is een nummer dat automatisch aan de computer wordt toegekend wanneer het internet wordt opgestart. Het IP-adres wordt niet gekoppeld aan de identificeerbare gegevens van de bezoeker, tenzij hiervoor uitdrukkelijk toestemming is verkregen.
      <br/>Persoonsgegevens worden zo minimaal mogelijk door de Eigenaar verzameld voor de hieronder genoemde doeleinden. Deze gegevens staan de Eigenaar ter beschikking doordat u uw gegevens zelf heeft ingevuld op het betreffende formulier van de Eigenaar, dan wel op andere wijze kenbaar heeft gemaakt. Deze betreffen uw identiteit, contactgegevens (zoals e-mailadres) en mogelijk een verwijzing naar openbare registraties van uw lidmaatschap bij instanties als de KNSB en/of de FIDE.
      </p>
      <h6>Op welke wijze worden de persoonsgegevens gebruikt?</h6>
      <p>De Eigenaar verwerkt uw persoonsgegevens ten behoeve van het uitoefenen van de doelstellingen van de Eigenaar. Deze doelstellingen
        omvatten onder andere:
      <ul>
        <li>ondersteuning van het organiseren van (schaak)toernooien;</li>
        <li>het ontsluiten van toernooi informatie;</li>
        <li>het faciliteren en bevorderen van de schaaksport in het algemeen.</li>
      </ul>
      Om deze doelstellingen te bereiken, worden uw gegevens op de volgende wijze verwerkt:
      <ul>
      <li>om contact met u te kunnen opnemen;</li>
      <li>om u informatie te sturen;</li>
      <li>voor het organiseren van schaakwedstrijden;</li>
      <li>voor het bijhouden van uitslagen en standen;</li>
      <li>ten behoeve van het ontsluiten van toernooi-uitslagen;</li>
      <li>voor het faciliteren en bevorderen van deelname aan schaakwedstrijden;</li>
      <li>voor het innen van toegezegde donaties;</li>
      <li>om te voldoen aan wettelijke verplichtingen.</li>
      </ul>  
      </p>
      <h6>Aan wie worden de persoonsgegevens verstrekt?</h6>
      <p>Uw persoonsgegevens worden niet actief met derden gedeeld. De naam, rating en uitslagen van spelers worden openbaar gepubliceerd.   
      Wilt u geen e-mail meer ontvangen met betrekking tot de activiteiten en nieuwtjes van de Eigenaar, dan kunt u gebruik maken van de afmeldmogelijkheid die is opgenomen in iedere e-mail die de Eigenaar verstuurt, of via instellingen op de website.
      </p>
      <h6>Geen commercieel gebruik</h6>
      <p>De Eigenaar zal uw persoonsgegevens niet verkopen, noch verhuren, verdelen of anderszins commercieel ter beschikking stellen aan derden, zonder uw voorafgaande toestemming.
      </p>
      <h6>Correctheid van de opgegeven persoonsgegevens</h6>
      <p>Door het meedelen van uw persoonsgegevens garandeert u dat deze juist en volledig zijn. De Eigenaar kan u bij het meedelen van valse identiteiten, onvolledige of onjuiste gegevens tijdelijk of definitief iedere toegang tot de websites of delen ervan ontzeggen.
      </p>
      <h6>Bewaren van persoonsgegevens</h6>
      <p>De Eigenaar bewaart persoonsgegevens niet langer dan noodzakelijk voor de verwezenlijking van de doeleinden waarvoor de gegevens worden verwerkt, en in elk geval zo lang als specifieke regelgeving vereist.
      </p>
      <h6>Hoe worden de persoonsgegevens beveiligd?</h6>
      <p>De Eigenaar neemt ten allen tijde passende technische en organisatorische maatregelen teneinde uw persoonsgegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking.
      </p>
      <h6>Verwijzingen naar andere websites</h6>
      <p>De website van de Eigenaar kan verwijzingen naar websites van andere entiteiten, zoals Eigenaren, bonden en bedrijven, bevatten. De Eigenaar is niet verantwoordelijk voor het gebruik van uw persoonsgegevens door die andere entiteiten. De Eigenaar raadt u aan om het privacybeleid van de desbetreffende entiteiten te raadplegen.
      </p>
      <h6>Uw privacy rechten</h6>
      <p>U heeft recht op inzage in uw persoonsgegevens, het recht om correctie, beperking of verwijdering van uw persoonsgegevens te vragen en daarnaast kunt u vragen om overdracht van uw gegevens. Ten slotte kunt u bezwaar maken tegen het gebruik van uw gegevens.
      Indien de verwerking van uw gegevens berust op toestemming, dan kunt u deze te allen tijde intrekken.
      U kunt uw verzoeken en/of vragen m.b.t. privacy gerelateerde zaken sturen aan de Eigenaar. U ontvangt dan zo spoedig mogelijk antwoord.
      U bent tevens gerechtigd een klacht in te dienen over het gebruik van uw gegevens bij de Autoriteit persoonsgegevens.
      </p>
      <h6>Wijzigingen van het privacybeleid</h6>
      <p>De Eigenaar behoudt zich het recht voor om dit privacybeleid statement te wijzigen. Check daarom regelmatig dit statement om op de hoogte te blijven van de meest recente versie.
      </p>
      <h6>Contact</h6>
      <p>Voor vragen en/of suggesties met betrekking tot dit privacybeleid statement en/of met betrekking tot de verwerking van uw persoonsgegevens kunt u zich richten tot de Eigenaar.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyStatement',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
