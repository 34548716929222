<template>
  <div class="details w3-theme-l2">{{ registration.name }}</div>
</template>

<script>

export default {
  name: 'RegistrationCard',
  components: {},
  props: {
    registration: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>


/* mobile */
@media screen and (max-width: 600px) {

  header {
    font-size: 5vw;
    padding: 2vw;
  }

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }

  .details {
    padding: 1vw;
  }

}

/* laptop */
@media screen and (min-width: 1281px) {

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }

  .details {
    padding: 1vw;
  }

}

</style>
