<template>
  <span v-if="username">
    <div class="bar-item" @click="signOut">
      <font-awesome-icon icon="sign-out-alt"/> 
      {{$t('app.menu.logout')}}
    </div>
    <router-link :to="/user-settings/ + username" class="bar-item">{{ username }}</router-link>
  </span>
  <router-link v-else to="/sign-in" class="bar-item">
    <font-awesome-icon icon="sign-in-alt"/>
    {{$t('app.menu.login')}}
  </router-link>
</template>

<script>
import {user} from "@/components/users";
import {Auth} from "@aws-amplify/auth";

export default {
  name: 'User',
  computed: {
    username: function () {
      return user.name
    }
  },
  methods: {
    signOut() {
      Auth.signOut()
          .catch(function (error) {
            console.log("error = ", JSON.stringify(error))
          })
    }
  }
}
</script>
