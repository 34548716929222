import {createWebHistory, createRouter} from "vue-router";
import Dashboard from "@/components/views/Dashboard.vue";
import Persons from "@/components/views/Persons.vue";
import Person from "@/components/views/Person.vue";
import Copyright from "@/components/views/Copyright.vue";
import TermsOfService from "@/components/views/TermsOfService.vue";
import PrivacyStatement from "@/components/views/PrivacyStatement.vue";
import Contact from "@/components/views/Contact.vue";
import SignIn from "@/components/users/SignIn";
import SignUp from "@/components/users/SignUp";
import SignUpConfirm from "@/components/users/SignUpConfirm";
import ResetPassword from "@/components/users/ResetPassword";
import ResetPasswordRequest from "@/components/users/ResetPasswordRequest";
import UserSettings from "@/components/users/UserSettings";
import ChangePassword from "@/components/users/ChangePassword";
import TournamentsDashboard from "@/components/tournaments/TournamentsDashboard";
import Tournament from "@/components/tournaments/Tournament";
import EditTournament from "@/components/tournaments/EditTournamentDetails";
import ClubsDashboard from "@/components/clubs/ClubsDashboard";
import Club from "@/components/clubs/Club";
import EditRegistration from "@/components/tournaments/registrations/EditRegistration";
import SelectUser from "@/components/users/SelectUser";

const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/sign-in",
        name: "SignIn",
        component: SignIn,
    },
    {
        path: "/sign-up",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/sign-up-confirm/:username",
        name: "SignUpConfirm",
        component: SignUpConfirm,
    },
    {
        path: "/user-settings/:username",
        name: "UserSettings",
        component: UserSettings,
    },
    {
        path: "/reset-password-request",
        name: "ResetPasswordRequest",
        component: ResetPasswordRequest,
    },
    {
        path: "/reset-password/:username",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/change-password/:username",
        name: "ChangePassword",
        component: ChangePassword,
    },
    {
        path: "/tournaments",
        name: "TournamentsDashboard",
        component: TournamentsDashboard,
    },
    {
        path: "/tournaments/:tournamentId",
        name: "Tournament",
        component: Tournament,
    },
    {
        path: "/tournaments/edit/:tournamentId",
        name: "EditTournamentDetails",
        component: EditTournament,
    },
    {
        path: "/tournaments/:tournamentId/registrations/:personId",
        name: "EditRegistration",
        component: EditRegistration,
    },
    {
        path: "/clubs",
        name: "ClubsDashboard",
        component: ClubsDashboard,
    },
    {
        path: "/federations/:federationCode/clubs/:clubCode",
        name: "Club",
        component: Club,
    },
    {
        path: "/persons",
        name: "Persons",
        component: Persons,
    },
    {
        path: "/persons/:id",
        name: "Person",
        component: Person,
    },
    {
        path: "/copyright/",
        name: "Copyright",
        component: Copyright,
    },
    {
        path: "/terms-of-service/",
        name: "TermsOfService",
        component: TermsOfService,
    },
    {
        path: "/privacy-statement/",
        name: "PrivacyStatement",
        component: PrivacyStatement,
    },
    {
        path: "/contact/",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/select-user/",
        name: "SelectUser",
        component: SelectUser,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
