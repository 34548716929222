<template>
  <div class="container">
    <div class="content">
      <header class="w3-theme-l1">
        <h4>speler: {{ registration.name }}</h4>
      </header>
      <div class="details w3-theme-l2">
        <input v-model="searchString" class="w3-input w3-border theme-input" type="text" placeholder="search">
        <div v-for="candidate in candidates" :key="candidate.personId" >
          <a href="#" @click="click(candidate)" >{{ candidate.name }}</a>
        </div>
        <div class="buttons">
          <div class="w3-btn theme-button-dark" @click="save">opslaan</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {RestAPI} from "@aws-amplify/api-rest";

const apiName = 'api';
const myInit = { // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
};

export default {
  name: 'EditTournamentDetails',
  components: {},
  data: function () {
    return {
      searchString: "",
      registration: {tournamentId: this.$route.params.tournamentId},
      candidates: []
    }
  },
  methods: {
    save: function () {
      let that = this
      const apiName = 'authenticated-api';
      const myInit = { // OPTIONAL
        body: this.registration,
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      };
      let path = '/tournaments/' + this.registration.tournamentId + '/registrations/' + this.registration.personId;
      RestAPI
          .put(apiName, path, myInit)
          .then(() => {
            that.$router.push({
              name: 'Tournament',
              params: {tournamentId: that.registration.tournamentId},
              query: {mode: 'show'}
            })
          })
          .catch(error => {
            console.log(error.response);
          });
    },
    click: function (candidate) {
      this.registration.name = candidate.name
      this.registration.personId = candidate.personId
    }
  },
  beforeMount() {
    if (this.$route.query.mode !== 'create') {
      let that = this
      let path = 'tournaments/' + this.registration.tournamentId + '/registrations/' + this.registration.personId
      RestAPI
          .get(apiName, path, myInit)
          .then(response => {
            that.registration = response
          })
          .catch(error => {
            if (error.status !== 404) {
              console.log("ERROR", JSON.stringify(error));
            }
          });
    }
  },
  watch: {
    searchString: function () {
      const searchPath = '/search?scope=person&searchString=';
      let that = this
      if (!this.searchString) {
        this.candidates = []
      } else {
        let fullPath = searchPath + encodeURIComponent(this.searchString);
        RestAPI
            .get(apiName, fullPath, myInit)
            .then(response => {
              that.candidates = response.items
            })
            .catch(error => {
              console.log(error.response);
            });
      }
    }
  },
}

</script>

<style scoped>

a {
  text-decoration: none;
}
a:hover {
  font-weight: bold;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
}

.container {
  width: 100vw;
  display: flex;
  justify-content: center;
}

/* mobile */
@media screen and (max-width: 600px) {

  .content {
    width: 96vw;
  }

  header {
    font-size: 5vw;
    padding: 2vw;
  }

  .details {
    padding: 2vw;
  }

}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {

  .content {
    width: 40vw;
  }

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }


  .details {
    padding: 1vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {

  .container {
    width: 30vw;
  }

  .content {
    width: 40vw;
  }

  header {
    font-size: 1.5vw;
    padding: 1vw;
  }


  .details {
    padding: 1vw;
  }

}
</style>
