<template>
  <div class="contact infoPage">
    <div class="infoHeader">
      <font-awesome-icon icon="envelope" class="w3-right headerIcon" /><h2>contact</h2>
    </div>
    <div class="infoText">   
      <p>lorem ipsum</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
