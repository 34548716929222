<template>
  <div class="infoPage">
    <div class="infoHeader">
      <font-awesome-icon icon="cog" class="w3-right headerIcon" /><h2>{{ $t('settings.settings') }}</h2>
    </div>
    <section class="topicSection">
      <h3>{{ $t('settings.userSettings') }}</h3>
      <div class="setting">
        <div class="settingName">{{ $t('settings.username') }}</div><div class="settingValue">{{username}}</div><br/>
      </div>  
      <div class="setting">
        <div class="settingName">{{ $t('settings.account') }}</div><div class="settingValue">{{ $t('settings.notLinked') }}<br/><router-link :to="'/select-user/'" class="specialLink">&gt; {{ $t('settings.linkToAccount') }}  &lt; </router-link></div>
      </div>  
      <div class="setting">
        <div class="settingName">{{ $t('settings.password') }}</div><div class="settingValue"> <router-link :to="'/change-password/' + username" class="specialLink">&gt; {{ $t('changePassword') }} &lt;</router-link></div>
      </div>  
    </section>
    <section class="topicSection">
      <h3>{{ $t('settings.interface') }}</h3>
      <div class="setting">
        <div class="settingName">{{ $t('settings.language') }}</div><div class="settingValue"> <select-locale></select-locale></div><br/>
      </div>  
      <div class="setting">
        <div class="settingName">{{ $t('settings.theme') }}</div><div class="settingValue"><select-theme></select-theme></div><br/>
      </div>  
    </section>
  </div>  
</template>

<script>

import {user} from "@/components/users/index";
import selectLocale from '../../locale/selectLocale.vue';
import SelectTheme from './SelectTheme.vue';

export default {
  components: { selectLocale, SelectTheme },
  name: 'UserSettings',
  computed: {
    username: function () {
      return user.name
    }
  },
  data: function () {
    return {}
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.setting {
  display: flex;
  flex-direction: row;
}

.topicSection {
  width: 100%;
  padding: 6px;
}

.topicSection h3 {
  border-bottom: 1px solid blue;
}

/* mobile */
@media screen and (max-width: 600px) {
  .settingName {
    width: 30vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .settingName {
    width: 30vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .settingName {
    width: 30vw;
  }
}
</style>
