<template>
  <div class="container">
    <tournament-details :tournamentId="$route.params.tournamentId"></tournament-details>
<!--    <groups-list :tournamentId="$route.params.tournamentId"></groups-list>-->
    <registrations-list :tournamentId="$route.params.tournamentId"></registrations-list>
  </div>
</template>

<script>
import TournamentDetails from "@/components/tournaments/TournamentDetails";
import RegistrationsList from "@/components/tournaments/registrations/RegistrationsList";
// import GroupsList from "@/components/tournaments/groups/GroupsList";

export default {
  name: 'Tournament',
  components: {RegistrationsList, TournamentDetails},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
  max-width: 100vw;
  display: grid;
  grid-gap: 1vw;
}

/* mobile */
@media screen and (max-width: 600px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
    padding: 1vw;
  }
}

/* ipad */
@media screen and (min-width: 601px) and (max-width: 1280px) {
  .container {
    grid-template-columns: 30vw 1fr;
    padding: 2vw;
  }
}

/* laptop */
@media screen and (min-width: 1281px) {
  .container {
    grid-template-columns: 30vw 1fr;
    padding: 2vw;
  }
}


</style>
