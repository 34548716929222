<template>
  <div class="copyright infoPage">
    <div class="infoHeader">
      <font-awesome-icon icon="copyright" class="w3-right headerIcon" /><h2>copyright</h2>
    </div>
    <div class="infoText">   
      <p>Op deze website en haar statische inhoud rust auteursrecht. Het is zonder uitdrukkelijke toestemming vooraf, niet toegestaan om de inhoud te kopiëren, verspreiden of op een andere manier openbaar te maken.</p>
      <p>Marcel Schröer <font-awesome-icon icon="copyright" /> 2021</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


